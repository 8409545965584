/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports

import { jsx } from "@emotion/react"
import { Fragment } from "react"
import { EmenuPhrases } from "../../models/emenu-model"
import { PrimaryButton } from "../common/primary-button"
import { SecondaryButton } from "../common/secondary-button"
import { emenu_start__button, emenu_start__buttons__wrapper } from "./e-menu-start.styles"
import { emenu_header, emenu_header__img } from "./e-menu-styles"

interface EmenuStartProps {
    phrases: EmenuPhrases,
    imageLink: string,
    onTipsLinkClick?: () => void,
    onOpenMenu: () => void,
    onOpenFeedback: () => void,
    isFeedbackEnabled?: boolean,
}

export const EmenuStart = ({ phrases, imageLink, onOpenMenu, onOpenFeedback, onTipsLinkClick, isFeedbackEnabled }: EmenuStartProps) => {
    return (
        <Fragment>
            <div css={emenu_header}>
                <img css={ emenu_header__img } src={ imageLink } alt='logo'/>
            </div>
            < div css={ emenu_start__buttons__wrapper } >
                {isFeedbackEnabled && (
                        <div css={emenu_start__button}><PrimaryButton label={phrases.to_feedback} onClick={onOpenFeedback}/></div>
                )}
                <div css={ emenu_start__button }><SecondaryButton label={phrases.open_menu} onClick={ onOpenMenu }/></div>
                { onTipsLinkClick &&
                    <div css={ emenu_start__button }>
                        <SecondaryButton
                            label={ phrases.tipping_staff }
                            onClick={onTipsLinkClick}
                        />
                    </div>
                }
            </div>
        </Fragment>
    )
}