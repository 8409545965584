export interface EmenuPhrases {
    to_feedback : string,
    open_menu: string,
    menu_title: string,
    menu_description: string,
    back: string,
    confirmation_request_title: string,
    confirmation_request_description: string,
    confirm_order: string,
    back_to_menu: string,
    order_will_be_ready: string,
    order_number_subject: string,
    order_went_wrong: string,
    tipping_staff: string,
}

export interface EmenuConfig {
    imageLink: string,
    teamId?: string,
    content: EmenuItemProps[],
}

export type EmenuProps = EmenuConfig & {
    phrases?: EmenuPhrases,
    onOpenMenu: () => void,
    onSubmitOrder: (orderedItemId: string) => Promise<number | undefined>,
    onOpenFeedback: () => void,
    onTipsLinkClick?: () => void,
    isFeedbackEnabled?: boolean,
}

export interface EmenuItemProps {
    id: string,
    name: string,
    description: string,
    imageLink: string,
}

export const defaultEmenuPhrases : EmenuPhrases = {
    to_feedback: 'Tips and feedback',
    open_menu: 'Make Order',
    menu_title: 'Menu',
    menu_description: 'Select one of the positions',
    back: 'Back',
    confirmation_request_title: 'Please confirm',
    confirmation_request_description: 'Once your order is confirmed, we will start cooking and bring it to your table',
    confirm_order: 'Confirm order',
    back_to_menu: 'Back to menu',
    order_will_be_ready: 'Order will be ready soon!',
    order_number_subject: 'Order number',
    order_went_wrong: "Oops, something went wrong :( Please try one more time or reach out receptionist",
    tipping_staff: "'Tipping a stuff member"
}
