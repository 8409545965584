const API_URL_PREFIX = 'https://magictap-me.herokuapp.com';
const API_URL_PREFIX_LOCAL = 'http://localhost:8080';

const resolveApiHost = () => {
    const isLocalEnv = process.env.NODE_ENV === 'development'
        && process.env.REACT_APP_DEV_CONNECTION_MOCK_REQUESTS;
    return isLocalEnv ? API_URL_PREFIX_LOCAL : API_URL_PREFIX;
};

export const apiHost = resolveApiHost();
