import { EmenuConfig } from "@magictap-me/widget-component/dist/esm/types/models/emenu-model";

interface FeedbackContent {
    rating: number;
    selectedCategories: string[];
    comment: string | null;
}

export interface FeedbackRequest {
    qrHash: string;
    content: FeedbackContent;
}

export enum FeedbackFor {
    TEAM,
    EMPLOYEE,
}

export interface Category {
    label: string;
    icon?: string;
    isActive: boolean;
    order: number;
}

export enum ErrorCode {
    INVALID_REQUEST_PARAMETER = 1,
    ENTITY_NOT_FOUND = 2,
    ENTITY_ALREADY_EXIST = 3,
    ACCOUNT_ALREADY_EXIST = 4,
    ACCOUNT_DOES_NOT_EXIST = 5,
    SESSION_IS_NOT_VALID = 6,
    INCORRECT_CREDENTIALS = 7,
    VERIFICATION_FAILED = 8,
    PERSON_DOES_NOT_EXIST = 9,
    BE_ERROR = -1,
}

export enum FeedbackRecipientType {
    TEAM,
    EMPLOYEE
}

export interface RecipientInfo {
    type: FeedbackRecipientType,
    name: string;
    info?: string;
    backgroundImage?: string;
    googleMapsLink?: string;
    tripadvisorLink?: string;
    instagramLink?: string;
}

export interface WidgetConfig {
    feedbackConfig: FeedbackConfig,
    menu: EmenuConfig
}

export interface FeedbackConfig {
    logo?: string;
    welcomeMessage: string;
    isCommentariesEnabled: boolean;
    recipient: RecipientInfo;
    categories: Category[];

    isPromoteToSocialEnabled: boolean;
    thankYouPageLogo: string | null;
    minimalFeedbackScoreForPromoteToSocial?: number;
    tipsLink?: string;
    isEnabled?: boolean;
}

export interface EmenuOrderResponse {
    number: number;
    status: string;
}

export interface MagictapResponse<T = unknown> {
    errorCode?: ErrorCode;
    message?: string;
    content?: T;

    // todo: revise if this attribute is needed or not
    type: any;
}
